<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid">
		<div class="p-col-12">
			<Toast/>
			<div class="card p-fluid">
				<Fieldset class="p-m-2" legend="Registro de Lectura de Agua">		
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12">
							<div class="p-field p-m-1">
								<label for="numeroContador" style="font-weight: bold;">Número Contador</label>
								<InputText size="20" id="numeroContador" type="text" v-model="numeroContador" @blur="buscarPorContador"/>
							</div>	
							<div class="p-field p-m-1">
								<label for="inmueble" style="font-weight: bold;">Inmueble</label>
								<div class="p-fluid p-inputgroup">
									<InputText size="135" id="inmueble" type="text" v-model="InmuebleDisplay" disabled/>																												
									<!-- <Button v-if="mostrarBotonBusquedaInmueble" icon="pi pi-search" @click="buscarInmueble"/>							
									<Button v-else icon="pi pi-times" class="p-button-warning" @click="buscarInmueble"/>							 -->
								</div>
							</div>						
						</div>			
						<div class="p-field p-col-8">
							<Fieldset legend="Lectura" class="p-m-2">
							<div class="p-fluid p-formgrid p-grid" >
								<div class="p-field p-col-6">
									<label for="icon" style="font-weight: bold;">Fecha Última Lectura</label>
									<div>
										{{ ultima_lectura.periodo_fin }}
									</div>									
								</div>
								<div class="p-field p-col-6">
									<label for="lectura_inicio" style="font-weight: bold;">Última Lectura</label>
									<div>
										{{ rec.lectura_inicio }}
									</div>
								</div>                          
								<div class="p-field p-col-6">
									<label for="icon" style="font-weight: bold;">Fecha Lectura</label>
									<Calendar id="icon" v-model="rec.periodo_fin" :showIcon="true" :manualInput="false" dateFormat="dd-MM-yy"/>									
								</div> 
								<div class="p-field p-col-6">
									<label for="lectura_fin" style="font-weight: bold;">Lectura Actual</label>
									<InputNumber id="lectura_fin" v-model="rec.lectura_fin" :minFractionDigits="0" :useGrouping="false" :class="{'p-invalid': v$.rec.lectura_fin.$error}"/>
									<small class="p-invalid" style="color: red"  v-if="v$.rec.lectura_fin.$invalid">La lectura debe ser superior a la lectura inicial!</small>
								</div>
								<div class="p-field p-col-6">
									<label for="consumo_periodo" style="font-weight: bold;">Consumo Período</label>
									<div>
										{{ rec.consumo_periodo }} {{ rec.dimensional }}									
									</div>
									<div v-if="rec.consumo_exceso_periodo > 0" style="font-weight: bold;color: red;"> 
										Exceso de {{ rec.consumo_exceso_periodo }} {{ rec.dimensional }}
									</div>
								</div>    
								<div class="p-field p-col-6  p-ai-right">
									<label for="total_cargo" style="font-size: large; font-weight: bold;">Total a Pagar</label>
									<div style="font-size: large; font-weight: 600;color: green;">
										{{ formatCurrency(rec.moneda_simbolo, rec.total_cargo) }}
									</div>
								</div>           					
							</div>                                           
						</Fieldset>
						</div>									
						<div class="p-field p-col-4">
							<Fieldset legend="Tarifa" class="p-m-2">                                                                
								<div class="p-fluid p-formgrid p-grid" >
									<div class="p-field p-col-12">
										<label for="tarifa" style="font-weight: bold;">Tarifa</label>
										<div style="font-weight: 400;color: green;">{{ rec.tarifa }}</div>										
									</div>	
									<div class="p-field p-col-12">
										<label for="costo_unidad" style="font-weight: bold;">Rango de Consumo</label>
										<div style="font-weight: 400;color: green;" v-if="rec.dimensional!=''">{{ rec.consumo_desde }} ~ {{ rec.consumo_hasta }} {{ rec.dimensional }}</div>
									</div>								
									<div class="p-field p-col-6">
										<label for="costo_unidad" style="font-weight: bold;">Costo Unidad</label>
										<div style="font-weight: 600;color: green;">{{ formatCurrency(rec.moneda_simbolo, rec.costo_unidad) }}</div>										
									</div>	
									<div class="p-field p-col-6">
										<label for="costo_unidad_exceso" style="font-weight: bold;">Costo Unidad Exceso</label>
										<div style="font-weight: 600;color: red;">{{ formatCurrency(rec.moneda_simbolo, rec.costo_unidad_exceso) }}</div>
									</div>	
								</div>	                                            
							</Fieldset>
						</div>	       
				</div>		
				<Button label="Registar" @click="registrarMov" icon="pi pi-check" :disabled="puedeRegistrar"/>					
				<!-- <Button label="Validar" @click="validar" />		 -->
				</Fieldset>		
			</div>
		</div>

		<!-- <Dialog v-model:visible="loading" :style="{width: '75px'}"  :modal="true">
			<ProgressSpinner />
		</Dialog> -->

        <MdsBuscarInmueble :mostrar="dlgBuscarInmueble" @cancelar="cerrarDlgInmueble" @seleccionar="selInmueble"/>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import MdsBuscarInmueble from '../../components/MdsBuscarInmueble.vue'
import MdsService from '../../service/mds.service';
import { useVuelidate } from '@vuelidate/core';
import { minValue } from '@vuelidate/validators';

const mayorOIgual = (val1, val2) => {
	console.log(`Lectura Final: ${val1}`);
	console.log(`Lectura Inicial: ${val2}`);

	var result = (val1 >= val2);
	console.log(`Mayor o Igual: ${result}`);

	return result;
};

export default {
	setup () {
    	return { v$: useVuelidate() }
  	},
	data() {
		return {
            dlgBuscarInmueble: false,
            numeroContador: null,			
			idInmueble: null,            
			InmuebleDisplay: null,
            mostrarBotonBusquedaInmueble: false,
			lkpCargosPendientes: null,
			loading: false,		
			ultima_lectura: {
				periodo_inicio: null,
				periodo_fin: null,
				lectura_inicio: null,
				lectura_fin: null
			},	
			rec: {
                id_inmueble: null,
                id_contador: 0,
                numero_contador: null,
                periodo_inicio: new Date(),
                periodo_fin: new Date(),
                lectura_inicio: null,
                lectura_fin: null,
                id_moneda: null,
				moneda: '',
				moneda_simbolo: '',
				id_dimensional: null,
				dimensional: null,
                tarifa: '',
                costo_unidad: 0.0,
                costo_unidad_exceso: 0.0,
                consumo_desde: 0,
                consumo_hasta: 0,
				consumo_periodo: 0.0,
				consumo_exceso_periodo: 0.0,
                total_cargo: 0.0				
			},
		}
	},
	validations () {
		return {
			rec: { 
                // lectura_fin: greaterThanOrEqual(this.rec.lectura_fin, this.rec.lectura_inicio)
				// lectura_fin: minValue(this.rec.lectura_inicio)
				lectura_fin: mayorOIgual(this.rec.lectura_fin, this.rec.lectura_inicio)
			}, 
		}
	},		
	components: { Loading, MdsBuscarInmueble },	
	mounted()
	{
		this.mov = this.newRec();
	},
	computed:
	{
		puedeRegistrar(){
			return !(this.rec.id_contador != '');
		},
		inmuebleSelected() {
			return !(this.selInmueble);
		},	
	},
	watch: {
		'rec.lectura_fin':  function (newVal, oldVal) {
			var consumo = this.rec.lectura_fin - this.rec.lectura_inicio;			
			if(consumo < 0)
			{
				consumo = 0;
			}
			this.rec.consumo_periodo = consumo;
		},
		'rec.consumo_periodo':  function (newVal, oldVal) {
			var montoBase = 0;
			var montoExceso = 0;
			this.rec.consumo_exceso_periodo = 0;

			if(this.rec.consumo_periodo > this.rec.consumo_hasta){
				//console.log(`Monto Base: ${this.rec.consumo_hasta} * ${this.rec.costo_unidad_lectura}` )
				montoBase = this.rec.consumo_hasta * this.rec.costo_unidad;
				this.rec.consumo_exceso_periodo = (this.rec.consumo_periodo - this.rec.consumo_hasta);
				montoExceso = this.rec.consumo_exceso_periodo * this.rec.costo_unidad_exceso;
			} else {
				//console.log(`Monto Base: ${this.rec.consumo_periodo} * ${this.rec.costo_unidad_lectura}` )
				 montoBase = this.rec.consumo_periodo * this.rec.costo_unidad;
				 montoExceso = 0;
			}			

			var totalPagar = montoBase + montoExceso;		
			if(totalPagar < 0)
			{
				totalPagar = 0;
			}
			this.rec.total_cargo = totalPagar;
		}		
	},
	methods: {
		async buscarPorContador() {
			var contador = {
				numero_contador:  this.numeroContador,
				id_inmueble: '',
				id_contador: 0
			};

			this.loading = true;			
			try{
				var data = await MdsService.GetContadorInfo(this.$store.state.auth.currentAppCtx, contador);
				this.loading = false;

				var len = data.length;
				if(len > 0) {
					this.InmuebleDisplay = data[0].inmueble;
					this.rec.id_contador = data[0].id_contador;
                	this.rec.id_inmueble = data[0].id_inmueble;
                	this.rec.numero_contador = data[0].numero_contador;
					this.rec.id_moneda = data[0].id_moneda;
					this.rec.moneda = data[0].moneda;
					this.rec.moneda_simbolo = data[0].moneda_simbolo;					
					this.rec.tarifa = data[0].tarifa;
					this.rec.id_dimensional = data[0].id_dimensional;
					this.rec.dimensional =  data[0].dimensional;
					this.rec.costo_unidad = data[0].costo_unidad_lectura;
					this.rec.costo_unidad_exceso = data[0].costo_unidad_exceso;
					this.rec.lectura_inicio = data[0].lectura_fin;
					this.rec.lectura_fin = data[0].lectura_fin;
					this.rec.consumo_desde = data[0].consumo_desde;
                	this.rec.consumo_hasta = data[0].consumo_hasta;
					this.rec.consumo_periodo = 0.0;
					this.rec.total_cargo = 0.0;

					this.cargarUltimaLectura();
				} else {
					alert('Contador no existe!');
				}
				
			}
			catch(err) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			}
		},
		async cargarUltimaLectura() {		
			this.loading = true;
			try{
				var lectura = await MdsService.GetUltimaLectura(this.$store.state.auth.currentAppCtx, this.rec);
				if(lectura.length>0){
					this.ultima_lectura.periodo_fin = lectura[0].periodo_fin;
					this.ultima_lectura.lectura_fin = lectura[0].lectura_fin;
				} else {
					this.ultima_lectura.periodo_fin = now.date();
					this.ultima_lectura.lectura_fin = 0;					
				}

				this.rec.periodo_inicio = this.ultima_lectura.periodo_fin;
				this.rec.lectura_inicio = this.ultima_lectura.lectura_fin;					
				this.rec.lectura_fin = this.rec.lectura_inicio;

				this.loading = false;
			}catch(err) {
				this.loading = false;
			}
		},
		formatCurrency(moneda, value) {
        //    return value.toLocaleString('en-US', {style: 'currency', currency: moneda});
			return `${moneda} ${value.toFixed(2)}`;
        },
		buscarInmueble() {
			if(!this.idinmueble){								
				this.dlgBuscarInmueble = true;
			} else {
				this.InmuebleDisplay = '(Todos)';
				this.idinmueble = null;			
			}
		},
		selInmueble(Inmueble)
		{
			this.idinmueble = Inmueble.id_inmueble;			
			this.InmuebleDisplay = Inmueble.display;

			this.dlgBuscarInmueble = false;	

			this.fetchData();
		},
		cerrarDlgInmueble()
		{
			this.dlgBuscarInmueble = false;			
		},
		newRec()
		{
			var rec = {
                id_inmueble: null,
                id_contador: 0,
                numero_contador: null,
                periodo_inicio: new Date(),
                periodo_fin: new Date(),
                lectura_inicio: null,
                lectura_fin: null,
                id_moneda: null,
				moneda: '',
				moneda_simbolo: '',
				id_dimensional: null,
				dimensional: null,
                tarifa: '',
                costo_unidad: 0.0,
                costo_unidad_exceso: 0.0,
                consumo_desde: 0,
                consumo_hasta: 0,
				consumo_periodo: 0.0,
				consumo_exceso_periodo: 0.0,
                total_cargo: 0.0
            }
			return rec;
		},
		async registrarMov() {
			this.loading = true;
			try {
				var data = await MdsService.registrarLectura(this.$store.state.auth.currentAppCtx, this.rec);

				this.ultima_lectura.periodo_inicio = null;
				this.ultima_lectura.periodo_fin = null;
				this.ultima_lectura.lectura_inicio = null;
				this.ultima_lectura.lectura_fin = null;
				
				this.numeroContador = null;
				this.idInmueble = null;
				this.InmuebleDisplay = null;

				this.rec = this.newRec();	
				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})
				this.submitted = false;
			}
		},
		async validar()
		{
			//this.v$.rec.lectura_fin.$touch;
			
			console.log(`Lectura Inicial: ${this.rec.lectura_inicio}`);
			console.log(`Lectura Final: ${this.rec.lectura_fin}`);

			var result = await this.v$.rec.lectura_fin.$invalid;

			if(result) {
				console.log('Invalido!');
				console.log(`Error: ${this.v$.rec.lectura_fin.$errors[0].$message}`);
			} else {
				console.log('Todo valido!');
			}
		}
	},
}
</script>

<style scoped>

</style>
